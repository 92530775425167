import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { useGetDeleteAdminQuery } from "../Redux/Slice/DeleteAdmin";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import Preloader from "./Preloader";
import { CSVLink } from "react-csv";
import { AiFillFilePdf } from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si";
import JsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import { useNavigate } from "react-router-dom";
import CustomPagination from "./CustomPagination";
import { ImageUploader } from "react-images-uploading";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { download, generateCsv, mkConfig } from "export-to-csv";




const ViewUser = () => {
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };



  /********************************preloader****************************************************/

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    /***********************Simulate an asynchronous operation (e.g., API call) with setTimeout****************************************/
    setTimeout(() => {
      setIsLoading(false);
      /**********************************Simulating a 1-second delay*********************************************/
    }, 1000);
  }, []);
  /***********************************clearForm*****************************************/

  function clearForm() {
    var fields = document.getElementsByClassName("name");
    for (var i = 0; i < fields.length; i++) {
      fields[i].value = "";
    }
  }

  /***********************************Add*****************************************/
  const [isValid, setIsValid] = useState(true);
  const [isValid1, setIsValid1] = useState(true);
  const handleInputChange = (e) => {
    const value = e.target.value;
    // Use regular expression to check if the input contains only numbers
    const isValidInput = /^\d{10}$/.test(value);

    setContactNo(value);
    setIsValid(isValidInput);
  };
  const handleInputChange1 = (e) => {
    const value = e.target.value;
    // Use regular expression to check if the input contains only numbers
    const isValidInput = /^\d{10}$/.test(value);

    setEditMobileNo(value);
    setIsValid(isValidInput);
  };
  const handleAlternateChange = (e) => {
    const value = e.target.value;
    // Use regular expression to check if the input contains only numbers
    const isValidInput = /^\d{10}$/.test(value);

    setAlternateContact(value);
    setIsValid1(isValidInput);
  };
  const handleAlternateChange1 = (e) => {
    const value = e.target.value;
    // Use regular expression to check if the input contains only numbers
    const isValidInput = /^\d{10}$/.test(value);

    setEditAlternateContact(value);
    setIsValid1(isValidInput);
  };
  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    // Allow only numeric characters and backspace
    if (charCode < 48 || charCode > 57 || e.target.value.length >= 10) {
      e.preventDefault();
    }
  };
  // ---------File-----------
  const handlefile = (e) => {
    const file = e.target.files[0];
    setCompanyLog(file);
  };

  // ---------GST to UpperCase-----------
  const handleGSTChange = (e) => {
    const newValue = e.target.value.toUpperCase(); // Convert input value to uppercase
    setGST(newValue);
  };
  // Excel
  const removekey = () => {
    if (countries && countries.length > 0) {
      const excelData =
        countries &&
        countries.map((obj) => {
          const { Id, Status, code, message, Password, AdminID, ...rest } = obj;
          return rest;
        });
      setExcel(
        excelData.map((obj, index) => {
          return { "S. No.": `${index + 1}`, ...obj };
        })
      );
    }
  };
  const [dCompanyPer, setDCompanyPer] = useState("");
  const [dGST, setDGST] = useState("");
  const [dReceiptPrefix, setDReceiptPrefix] = useState("");
  const [dCompanyUrl, setDCompanyUrl] = useState("");
  const [dInvoicePrefix, setDInvoicePrefix] = useState("");

  const navigate = useNavigate();

  const closeButton = document.querySelector(".afs");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".btn-close");
  const form = new FormData();
 
  const originalDate = new Date();
  const formattedDate = formatDate(originalDate);
  const formData = new FormData();
  const [Edittext, setEditText] = useState("");
  const [text, setText] = useState("");
  const [delId, setDelId] = useState();
  const [Status, setStatus] = useState(false);
  const [userName, setUserName] = useState("");
  const [Mail, setMail] = useState("");
  const [ContactNo, setContactNo] = useState("");
  const [AlternateContact, setAlternateContact] = useState("");
  const [Password, setPassword] = useState("");
  const [Address, setAddress] = useState("");
  const [Qualification, setQualification] = useState("");
  const [ActFrom, setActFrom] = useState(
    ""
    // new Date().toISOString().split("T")[0]
  );
  const [ActTo, setActTo] = useState(
    ""
    // new Date().toISOString().split("T")[0]
  );
  const [ActDate, setActDate] = useState("");
  const [ContactPersonName, setContactPersonName] = useState("");
  const [Otherdetail, setOtherdetail] = useState("");
  const [CompanyPer, setCompanyPer] = useState("");
  const [GST, setGST] = useState("");
  const [CompanyLog, setCompanyLog] = useState("");
  const [ReceiptPrefix, setReceiptPrefix] = useState("");
  const [CompanyUrl, setCompanyUrl] = useState("");
  const [InvoicePrefix, setInvoicePrefix] = useState("");
  const [isStatus, setIsStatus] = useState("0");


  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.append("UserName", userName);
    formData.append("Mail", Mail);
    formData.append("MobileNo", ContactNo);
    formData.append("Password", Password);
    formData.append("Address", Address);
    formData.append("Qualification", Qualification);
    formData.append("ActFrom", ActFrom);
    formData.append("ActTo", ActTo);
    formData.append("AlternateContact", AlternateContact);
    // formData.append("ActDate", formattedDate);
    formData.append("ContactPersonName", ContactPersonName);
    formData.append("CompanyPer", CompanyPer);
    formData.append("CompanyLog", CompanyLog);
    formData.append("GST", GST);
    formData.append("InvoicePrefix", InvoicePrefix);
    formData.append("ReceiptPrefix", ReceiptPrefix);
    formData.append("CompanyUrl", CompanyUrl);
    formData.append("OtherDetail", Otherdetail);
    formData.append("AddedBy", localStorage.getItem("id"));

    /*******************Send the formData to the server using an API call or fetch()*********************/
    const access_token = localStorage.getItem("access_token");

    try {
      const response = await fetch(
        " https://api.lawyerhunt.in/api/SuperAdmin/AddAdmin",
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `${access_token}`,
            Accept: "*/*",
            ContentType: "multipart/form-data",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }
      /******************************* Handle the response from the server*********************************/
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.result[0].code === "200") {
        getCountries();
        clearForm();
        closeButton.click();
        toast.success("Admin Added !");
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  /******************************* Delete*********************************/

  const handleDeleteAdmin = async () => {
    const access_token = localStorage.getItem("access_token");
    formData.append("ID", delId);
    try {
      const response = await fetch(
        " https://api.lawyerhunt.in/api/SuperAdmin/DeleteAdmin",
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `${access_token}`,
            Accept: "*/*",
            ContentType: "multipart/form-data",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }
      /******************************* Handle the response from the server*********************************/

      const data = await response.json();
      console.log(data.result.message);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.result.code === 200) {
        getCountries();
        toast.success("Admin Deleted !");
        closeDelete.click();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleKeyPressalpha = (e) => {
    const charCode = e.which || e.keyCode;

    // Allow alphabet characters, space, comma, and backspace
    if (
      (charCode < 65 || charCode > 90) && // A-Z
      (charCode < 97 || charCode > 122) && // a-z
      charCode !== 8 && // Backspace
      charCode !== 32 && // Space
      charCode !== 44 // Comma
    ) {
      e.preventDefault();
    }
  };
  /******************************* Edit*********************************/

  const EditAdmin = new FormData();
  const [EditUserID, setEditUserID] = useState();
  const [edituserName, setEditUserName] = useState();
  const [EditMail, setEditMail] = useState("");
  const [EditMobileNo, setEditMobileNo] = useState("");
  const [EditAlternateContact, setEditAlternateContact] = useState("");
  const [EditPassword, setEditPassword] = useState("");
  const [EditAddress, setEditAddress] = useState("");
  const [EditQualification, setEditQualification] = useState("");
  const [EditActFrom, setEditActFrom] = useState("");
  // new Date().toISOString().split("T")[0]

  const [EditActTo, setEditActTo] = useState(
    ""
    // new Date().toISOString().split("T")[0]
  );
  const [EditActDate, setEditActDate] = useState(
    ""
    // new Date().toISOString().split("T")[0]
  );
  const [EditOtherdetail, setEditOtherdetail] = useState("");
  const [CompanyCode, setCompanyCode] = useState("");
  const [EditContactPersonName, setEditContactPersonName] = useState("");
  const [showPassword, setShowPassword] = useState("password");
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    EditAdmin.append("UserID", EditUserID);
    if (edituserName.length < 2) {
      toast.error("Invalid Name");
    } else {
      EditAdmin.append("UserName", edituserName);
    }
    EditAdmin.append("Mail", EditMail);
    if (EditMobileNo.length === 10) {
      EditAdmin.append("MobileNo", EditMobileNo);
    } else {
      toast.error("Invalid Mobile No");
    }
    if (typeof EditAlternateContact === "undefined") {
      EditAdmin.append("AlternateContact", "");
    }
    // EditAdmin.append("AlternateContact", EditAlternateContact);

    EditAdmin.append("ContactPersonName", EditContactPersonName);
    EditAdmin.append("Password", EditPassword);
    EditAdmin.append("Address", EditAddress);
    EditAdmin.append("Qualification", EditQualification);
    if (EditActFrom && EditActFrom != "0000-00-00") {
      EditAdmin.append("ActFrom", EditActFrom);
    } else {
      EditAdmin.append("ActFrom", "");
    }
    if (EditActTo && EditActTo != "0000-00-00") {
      EditAdmin.append("ActTo", EditActTo);
    } else {
      EditAdmin.append("ActTo", "");
    }
    if (EditActDate && EditActDate != "0000-00-00") {
      EditAdmin.append("ActDate", EditActDate);
    } else {
      EditAdmin.append("ActDate", "");
    }
    EditAdmin.append("OtherDetail", Edittext);
    EditAdmin.append("UpdatedBy", localStorage.getItem("id"));
    for (let pair of EditAdmin.entries()) {
      let fieldName = pair[0];
      let fieldValue = pair[1];

      if (fieldValue === "undefined") {
        // Set the field value to three spaces
        EditAdmin.set(fieldValue, "");
      }
      console.log(fieldName, fieldValue);
    }
    /**********************Send the formData to the server using an API call or fetch()****************************/

    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/SuperAdmin/EditAdmin",
        {
          method: "POST",
          body: EditAdmin,
          headers: {
            Authorization: localStorage.getItem("access_token"),
            Accept: "*/*",
            ContentType: "multipart/form-data",
          },
        }
      );
      const data1 = await response.json();

      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }
      // Handle the response from the server

      if (data1 && data1.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }
      if (data1.result[0].message === "Successs") {
        getCountries();
        clearForm();
        closeEdit.click();
        toast.success("Admin Updated !", {
          position: "top-right",
          /**********************************Simulating a 3-second delay*********************************************/

          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(data1.result[0].message);
        if (data1.result.code === 400) {
          alert(data1.result.message);

          navigate("/");
        }
      } else {
        toast.error(data1.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  /**********************************View*********************************************/

  const [excel, setExcel] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredcountries, setFilteredCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const getCountries = async () => {
    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/SuperAdmin/ViewAdmin",
        {
          method: "POST",

          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setCountries(data.result);

      setFilteredCountries(data.result);
    } catch (error) {
      console.error(error);
    }
  };
  const adminById = new FormData();

  const updateUser = async (id) => {
    adminById.append("UserID", id);

    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/SuperAdmin/ViewAdminById",
        {
          method: "POST",
          body: adminById,

          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setEditUserID(data.result[0].Id);
      setEditUserName(data.result[0].Name);
      setEditMail(data.result[0].Email);
      setEditMobileNo(data.result[0].MobileNo);
      setEditPassword(data.result[0].Password);
      setEditAddress(data.result[0].Address);
      setEditQualification(data.result[0].Qualification);
      setEditActFrom(data.result[0].ActivateFrom);
      setEditActTo(data.result[0].ActivateTo);
      setEditActDate(data.result[0].ActivationDate);
      setEditText(data.result[0].Otherdetail);
      setStatus(data.result[0].Status);
      setCompanyCode(data.result[0].CompanyCode);
      setDCompanyPer(data.result[0].CompanyPer);
      setDGST(data.result[0].GST);
      setDReceiptPrefix(data.result[0].ReceiptPrefix);
      setDCompanyUrl(data.result[0].CompanyUrl);
      setDInvoicePrefix(data.result[0].InvoicePrefix);

      setEditAlternateContact(data.result[0].AlternateContact);
      setEditContactPersonName(data.result[0].ContactPersonName);
    } catch (error) {
      console.error(error);
    }
  };

  const formatDate1 = (apiDate) => {
    const dateParts = apiDate.split("-");
    const day = dateParts[2];
    const month = dateParts[1];
    const year = dateParts[0];

    return `${day}-${month}-${year}`;
  };
  const customStyles = {
    cells: {
      style: {
        fontSize: "14px",
      },
    },

    headRow: {
      style: {
        color: "white",
        fontSize: "13px",
        backgroundColor: "#7a0103",
        borderRadius: "10px 10px 0px 0px",
      },
    },
  };


  //---------- Add DataTable By Kiran , date 21-11-23-------------


  const excelData =
    countries &&
    countries.map((obj) => {
      const { Id, Status, CourtID, RoleID, Password, code, message, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const formattedDate = new Date().toISOString().slice(0, 10); // Get today's date
    const filename = `Bank|LegalCRM__${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    download(csvConfig)(csv);
  };



  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const selectedColumnIds = [
       'CompanyCode', 
       'Name', 
       'ContactPersonName',
       'MobileNo', 
       'ActivateFrom',
      'ActivateTo',
       ];

    // Add a serial number column
    const columnsToExport = [
      { id: 'serialNo', header: 'S No.' }, // Add this line
      ...columns.filter((column) => selectedColumnIds.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    const filename = `Court|LegalCRM_${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("Name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    
    const tableData = rows.map((row, index) => {
      return [
        { content: (index + 1).toString(), styles: { fontStyle: 'bold' } }, // Serial number
        ...columnsToExport.slice(1).map((column) => { // Exclude the first column (serial number) when mapping
          const value = column.id in row.original ? row.original[column.id] : '';
          return value !== null && value !== undefined ? value : '';
        }),
      ];
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY:watermarkHeight +10
    });

    // Add a heading to the table
    doc.text('Company list', 84, 20);
    // Save the PDF to a Blob
    const pdfBlob = doc.output('blob');

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, '_blank');

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };



  //               -----------Column  add by kiran ,date 21-11-23-------------
  const columnHelper = createMRTColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      header: "Action",
      minSize: "10px",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>

              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLong"
                onClick={() => updateUser(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/edit.svg"
                  alt="Edit"
                  title="Edit"
                />
              </span>


              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongEditView"
                onClick={() => updateUser(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eyeopen.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              
              <span>
                <a
                  // style={{
                  //   cursor: "pointer",
                  //   display: row.row.original.Status === "0" ? "none" : "block",
                  // }}
                  onClick={() => Active(row.row.original)}                >
             
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    /> 
                    ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                    )}
                 
                </a>
              </span>

              <span
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                style={{ cursor: "pointer" }}
                onClick={() => setDelId(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/delete.svg"
                  alt="Delete"
                  title="Delete"
                />
              </span>

            </div>
          </>
        );
      },
    }),



    columnHelper.accessor("CompanyCode", {
      header: "Company Code",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CompanyCode === null ||
              row.row.original.CompanyCode === ""
              ? "-"
              : row.row.original.CompanyCode}
          </div>
        );
      },
    }),

    columnHelper.accessor("Name", {
      header: "Company Name",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Name === null ||
              row.row.original.Name === ""
              ? "-"
              : row.row.original.Name}
          </div>
        );
      },
    }),

    columnHelper.accessor("ContactPersonName", {
      header: "Contact Person",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.ContactPersonName === null ||
              row.row.original.ContactPersonName === ""
              ? "-"
              : row.row.original.ContactPersonName}
          </div>
        );
      },
    }),

    columnHelper.accessor("MobileNo", {
      header: "Contact No.",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.MobileNo === null ||
              row.row.original.MobileNo === ""
              ? "-"
              : row.row.original.MobileNo}
          </div>
        );
      },
    }),



    columnHelper.accessor("ActivateFrom", {
      header: "Activate From",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.ActivateFrom === null ||
              row.row.original.ActivateFrom === ""
              ? "-"
            : formatDate1(row.row.original.ActivateFrom)}
          </div>
        );
      },
    }),



    columnHelper.accessor("ActivateTo", {
      header: "Activate To",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.ActivateTo === null ||
              row.row.original.ActivateTo === ""
              ? "-"
              : formatDate1(row.row.original.ActivateTo)}
          </div>
        );
      },
    }),

    columnHelper.accessor("ActivationDate", {
      header: "Activation Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.ActivationDate === null ||
              row.row.original.ActivationDate === ""
              ? "-"
              : formatDate1(row.row.original.ActivationDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("Email", {
      header: "Email",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Email === null ||
              row.row.original.Email === ""
              ? "-"
              : row.row.original.Email}
          </div>
        );
      },
    }),

    columnHelper.accessor("Address", {
      header: "Address",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Address === null ||
              row.row.original.Address === ""
              ? "-"
              : row.row.original.Address}
          </div>
        );
      },
    }),

    columnHelper.accessor("Qualification", {
      header: "Qualification",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Qualification === null ||
              row.row.original.Qualification === ""
              ? "-"
              : row.row.original.Qualification}
          </div>
        );
      },
    }),

    columnHelper.accessor("CompanyUrl", {
      header: "Company Url",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CompanyUrl === null ||
              row.row.original.CompanyUrl === ""
              ? "-"
              : row.row.original.CompanyUrl}
          </div>
        );
      },
    }),
    columnHelper.accessor(" CompanyPer", {
      header: "Company's Percentage ",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CompanyPer === null ||
              row.row.original.CompanyPer === ""
              ? "-"
              : row.row.original.CompanyPer}
          </div>
        );
      },
    }),


    columnHelper.accessor("Otherdetail", {
      header: "Comments",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Otherdetail === null ||
              row.row.original.Otherdetail === ""
              ? "-"
              : row.row.original.Otherdetail}
          </div>
        );
      },
    }),

    columnHelper.accessor("GST", {
      header: "GST",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.GST === null ||
              row.row.original.GST === ""
              ? "-"
              : row.row.original.GST}
          </div>
        );
      },
    }),

    columnHelper.accessor("ReceiptPrefix", {
      header: "ReceiptPrefix",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.ReceiptPrefix === null ||
              row.row.original.ReceiptPrefix === ""
              ? "-"
              : row.row.original.ReceiptPrefix}
          </div>
        );
      },
    }),

    columnHelper.accessor("InvoicePrefix", {
      header: "Invoice Prefix",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.InvoicePrefix === null ||
              row.row.original.InvoicePrefix === ""
              ? "-"
              : row.row.original.InvoicePrefix}
          </div>
        );
      },
    }),




    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return (
          <div>{row.row.original.Status === "1" ? "Active" : "DeActive"}</div>
        );
      },
    }),


  ];

  //  --------------Data Table Kiran,date 21-11-23 ---------------
  // const table = useMaterialReactTable({
  //   columns,
  //   data: countries,
  //   enableRowSelection: false,
  //   columnFilterDisplayMode: "popover",

  //   enableStickyHeader: true,
  //   enableRowNumbers: true,
  //   rowNumberDisplayMode: 'original',
   
  //   muiPaginationProps: {
  //     rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
  //     showFirstButton: true,
  //     showLastButton: true,
  //   },
  //   positionToolbarAlertBanner: "bottom",
  //   renderTopToolbarCustomActions: ({ table }) => (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         gap: "16px",
  //         padding: "8px",
  //         flexWrap: "wrap",
  //       }}
  //     >
  //       <Button
  //         //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
  //         onClick={handleExportData}
  //         startIcon={<FileDownloadIcon />}
  //       >
  //         Export Excel
  //       </Button>

  //       <Button
  //         disabled={table.getPrePaginationRowModel().rows.length === 0}
  //         //export all rows, including from the next page, (still respects filtering and sorting)
  //         onClick={() =>
  //           handleExportRows(table.getPrePaginationRowModel().rows)
  //         }
  //         startIcon={<FileDownloadIcon />}
  //       >
  //         Export PDF
  //       </Button>
  //     </Box>
  //     // <Box
  //     //   sx={{
  //     //     display: "flex",
  //     //     gap: "16px",
  //     //     padding: "8px",
  //     //     flexWrap: "wrap",
  //     //   }}
  //     // ></Box>
  //   ),
  // });

  const table = useMaterialReactTable({
    columns,
    data: countries,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Status: false,
        AddedBy: false,
        UpdatedBy: false,
        UpdatedDate: false,
        CreatedDate: false,
      },
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    muiPaginationProps: {
      // rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export PDF
        </Button>
      </Box>
    ),
  });

  useEffect(() => {
    getCountries();
  }, []);
  useEffect(() => {
    const result = countries.filter((country) => {
      return country.Name.toLowerCase().match(search.toLowerCase());
    });
    setFilteredCountries(result);
  }, [search]);

  function PasswordInput() {
    const [showPassword, setShowPassword] = useState(false);
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleTextChange = (event) => {
    setOtherdetail(event.target.value);
  };
  const handleTextChange1 = (event) => {
    setEditOtherdetail(event.target.value);
  };
  const Active = async (row) => {
    const formData = new FormData();
    formData.append("ID", row.Id);
    formData.append("Status", row.Status == "1" ? "0" : "1");


    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/SuperAdmin/ActiveAdminStatus",
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );

      const data = await response.json();

       if (data && data.result.message === "Status Updated Successfully") {
        if (row.Status === "1") {
         toast.error("Status Deactivated");
        } else{
         toast.success("Status Activated");
        }
        getCountries();
       }
     }
     catch (error) {
       console.error(error);
     }
   };

 
  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${dd}-${mm}-${yyyy}`;
  };
 

  const maxNumber = 5;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setCompanyLog(imageList);
  };
 
  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <div>
          <div className="wraper">
            <Header />
            <div className="mainWraper">
              
              <Sidebar />
              <div className="pageBody">
                <div className="pageContent">
                
                        <div className="PageTitle">
                          <h3>Company List</h3>
                          <a
                            type="button"
                            className="btn btn-primary btn_client"
                            data-toggle="modal"
                            data-target="#exampleModalLon"
                          >
                              Add Company
                            <img src="assets/img/icons/add.svg" alt="" />
                          
                          </a>
                        </div>
                        &nbsp;
                        <div className="searDesign">
                          {countries &&
                            countries.length > 0 &&
                            countries[0].message === "Data not found" ? (
                            <h3 className="visuald">
                              There are no records to display
                            </h3>
                          ) : (
                            <>

                              {/* -------------- DataTable by kiran-----------  */}

                              <MaterialReactTable className="DatabaseLet" table={table} />
                            </>
                          )}
                          {/********************************************************************** add form **************************************************/}
                          <div
                            className="modal fade"
                            id="exampleModalLon"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLongTitle"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog"
                              role="document"
                              style={{ maxWidth: "70%" }}
                            >
                              <div className="modal-content">
                                <div className="popup_modal ">
                                  <button
                                    type="button"
                                    className="close btn_popup afs"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                  <div className="main_heading">
                                    <h2>Add Company </h2>
                                  </div>
                                  <div className="dashed_popup">
                                    <form role="form" onSubmit={handleSubmit}>
                                      <div className="form_flex">
                                        <div className="form-group">
                                          <label for="uname">
                                            Company 's Name{" "}
                                            <text className="starm">*</text>
                                          </label>
                                          <input
                                            autoComplete="off"
                                            className="name"
                                            type="text"
                                            placeholder="Enter Company 's Name"
                                            onChange={(e) =>
                                              setUserName(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label for="uname">
                                            Email{" "}
                                            <text className="starm">*</text>
                                          </label>
                                          <input
                                            autoComplete="off"
                                            className="name"
                                            type="email"
                                            placeholder="Enter Email"
                                            onChange={(e) =>
                                              setMail(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label for="uname">
                                            Contact Person Name{" "}
                                            <text className="starm">*</text>
                                          </label>
                                          <input
                                            onKeyPress={handleKeyPressalpha}
                                            autoComplete="off"
                                            className="name"
                                            type="text"
                                            placeholder="Enter Contact Person Name"
                                            onChange={(e) =>
                                              setContactPersonName(
                                                e.target.value
                                              )
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="form_flex">
                                        <div className="form-group">
                                          <label>
                                            Password{" "}
                                            <text className="starm">*</text>
                                          </label>
                                          <input
                                            className="name"
                                            autoComplete="off"
                                            type={
                                              showPassword ? "password" : "text"
                                            }
                                            placeholder="Enter Password"
                                            id="#password"
                                            onChange={(e) =>
                                              setPassword(e.target.value)
                                            }
                                            required
                                          />

                                          <FontAwesomeIcon
                                            icon={
                                              showPassword ? faEyeSlash : faEye
                                            }
                                            onClick={togglePasswordVisibility}
                                            className="password-icon"
                                          />
                                        </div>

                                        <div className="form-group">
                                          <label for="uname">
                                            Mobile No{" "}
                                            <text className="starm">*</text>
                                          </label>
                                          <input
                                            onKeyPress={handleKeyPress}
                                            maxLength={10}
                                            autoComplete="off"
                                            className="name"
                                            type="text"
                                            placeholder="Enter Mobile No"
                                            onChange={handleInputChange}
                                            required
                                          />
                                          {!isValid && (
                                            <p style={{ color: "red" }}>
                                              Please enter a valid ten-digit
                                              mobile no.
                                            </p>
                                          )}
                                        </div>
                                        <div className="form-group">
                                          <label for="uname">
                                            Alternate Contact no.
                                          </label>
                                          <input
                                            onKeyPress={handleKeyPress}
                                            maxLength={10}
                                            autoComplete="off"
                                            className="name"
                                            type="text"
                                            placeholder="Enter Alternate Mobile No"
                                            onChange={handleAlternateChange}
                                          />
                                          {!isValid1 && (
                                            <p style={{ color: "red" }}>
                                              Please enter a valid ten-digit
                                              Mobile No.
                                            </p>
                                          )}
                                        </div>
                                      </div>

                                      <div className="form_flex">
                                        <div className="form-group">
                                          <label htmlFor="uname">
                                            Activate From{" "}
                                            <text className="starm">*</text>
                                          </label>
                                          <input
                                            className="name"
                                            autoComplete="off"
                                            type="date"
                                            placeholder="Enter Address"
                                            onChange={(e) =>
                                              setActFrom(e.target.value)
                                            }
                                            required
                                          />
                                        </div>

                                        <div className="form-group">
                                          <label for="uname">
                                            Activate To{" "}
                                            <text className="starm">*</text>
                                          </label>
                                          <input
                                            className="name"
                                            autoComplete="off"
                                            type="date"
                                            placeholder="Enter Address"
                                            onChange={(e) =>
                                              setActTo(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label for="uname">
                                            Qualification
                                          </label>
                                          <input
                                            className="name"
                                            autoComplete="off"
                                            type="text"
                                            placeholder="Enter Qualification"
                                            onChange={(e) =>
                                              setQualification(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                 
                                      <div className="form_flex">
                                        <div className="form-group">
                                          <label for="uname">
                                            Invoice Prefix
                                          </label>
                                          <input
                                            autoComplete="off"
                                            className="name"
                                            type="text"
                                            placeholder="Enter Invoice Prefix"
                                            onChange={(e) =>
                                              setInvoicePrefix(e.target.value)
                                            }
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label for="uname">
                                            Receipt Prefix
                                          </label>
                                          <input
                                            autoComplete="off"
                                            className="name"
                                            type="text"
                                            placeholder="Enter Receipt Prefix"
                                            onChange={(e) =>
                                              setReceiptPrefix(e.target.value)
                                            }
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label for="uname">
                                            Address{" "}
                                            <text className="starm">*</text>
                                          </label>
                                          <input
                                            className="name"
                                            autoComplete="off"
                                            type="text"
                                            placeholder="Enter Address"
                                            onChange={(e) =>
                                              setAddress(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="form_flex">
                                        <div className="form-group">
                                          <label for="uname">
                                            Company 's Percentage
                                          
                                          </label>
                                          <input
                                            onKeyPress={handleKeyPress}
                                            autoComplete="off"
                                            className="name"
                                            type="text"
                                            placeholder="Enter Company 's Percentage"
                                            onChange={(e) =>
                                              setCompanyPer(e.target.value)
                                            }
                                           
                                          />
                                        </div>
                                        {/* <div className="form-group">
                                          <label for="uname">
                                            Company Logo
                                          </label>
                                          <input
                                            autoComplete="off"
                                            className="name"
                                            type="file"
                                            placeholder="Enter Logo"
                                            onChange={handlefile}
                                          />
                                        </div> */}
                                        <div className="form-group">
                                          <label for="uname">
                                            GST
                                            
                                          </label>
                                          <input
                                            // onKeyPress={handleKeyPressalpha}
                                            autoComplete="off"
                                            className="name"
                                            type="text"
                                            placeholder="Enter GST"
                                            onChange={handleGSTChange}
                                          
                                          />
                                        </div>
                                      </div>
                                      <div className="form_flex">
                                        <div className="form-group">
                                          <label for="uname">Company Url</label>
                                          <input
                                            // onKeyPress={handleKeyPressalpha}
                                            autoComplete="off"
                                            className="name"
                                            type="text"
                                            placeholder="Enter Company Url"
                                            onChange={(e) =>
                                              setCompanyUrl(e.target.value)
                                            }
                                          />
                                        </div>

                                        <div className="form-group">
                                          <label for="uname">
                                            Other Details
                                          </label>
                                          <textarea
                                            className="name"
                                            onChange={handleTextChange}
                                            rows={4}
                                            cols={50}
                                            placeholder="Enter your text here..."
                                          />
                                        </div>
                                      </div>
                                      <div className="btn_main">
                                        <button
                                          type="submit"
                                          className="btn_save"
                                        >
                                          Save
                                        </button>
                                        <button
                                          type="button"
                                          onClick={clearForm}
                                          className="btn_save btn_cancle"
                                          data-dismiss="modal"
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/********************************** edit from ************************************************************/}
                          <div
                            className="modal fade"
                            id="exampleModalLong"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLongTitle"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog"
                              role="document"
                              style={{ maxWidth: "50%" }}
                            >
                              <div className="modal-content">
                                <div className="popup_modal ">
                                  <button
                                    type="button"
                                    className="e_popup close btn_popup"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                  <div className="main_heading">
                                    <h2>Edit Company</h2>
                                  </div>
                                  <div className="dashed_popup">
                                    <form
                                      role="form "
                                      onSubmit={handleEditSubmit}
                                    >
                                      <div className="form_flex">
                                        <div className="form-group">
                                          <label for="uname">
                                            Company's Name
                                           
                                          </label>
                                          <input
                                            className="name"
                                            autoComplete="off"
                                            value={edituserName}
                                            type="text"
                                            placeholder="Enter Company's Name"
                                            onChange={(e) =>
                                              setEditUserName(e.target.value)
                                            }
                                            
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label for="uname">
                                            Email
                                            <text className="starm">*</text>
                                          </label>
                                          <input
                                            className="name"
                                            autoComplete="off"
                                            value={EditMail}
                                            type="email"
                                            placeholder="Enter Email"
                                            onChange={(e) =>
                                              setEditMail(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="form_flex">
                                        <div className="form-group">
                                          <label for="uname">
                                            Mobile No
                                            <text className="starm">*</text>
                                          </label>
                                          <input
                                            className="name"
                                            onKeyPress={handleKeyPress}
                                            autoComplete="off"
                                            value={EditMobileNo}
                                            type="text"
                                            placeholder="Enter Mobile No"
                                            onChange={handleInputChange1}
                                            required
                                          />
                                          {!isValid && (
                                            <p style={{ color: "red" }}>
                                              Please enter a valid ten-digit
                                              Mobile No.
                                            </p>
                                          )}
                                        </div>

                                        <div className="form-group">
                                          <label for="uname">
                                            Alternate Contact no.
                                          </label>
                                          <input
                                            value={EditAlternateContact}
                                            onKeyPress={handleKeyPress}
                                            maxLength={10}
                                            autoComplete="off"
                                            className="name"
                                            type="text"
                                            placeholder="Enter Alternate Mobile No"
                                            onChange={handleAlternateChange1}
                                          />
                                          {!isValid1 && (
                                            <p
                                              style={{
                                                color: "red",
                                                marginRight: "10px",
                                              }}
                                            >
                                              Please enter a valid ten-digit
                                              Mobile No.
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="form_flex">
                                        <div className="form-group">
                                          <label for="uname">
                                            Qualification
                                          </label>
                                          <input
                                            value={EditQualification}
                                            className="name"
                                            autoComplete="off"
                                            type="text"
                                            placeholder="Enter Qualification"
                                            onChange={(e) =>
                                              setEditQualification(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label for="uname">
                                            Contact Person Name
                                            <text className="starm">*</text>
                                          </label>
                                          <input
                                            value={EditContactPersonName}
                                            className="name"
                                            autoComplete="off"
                                            onKeyPress={handleKeyPressalpha}
                                            type="text"
                                            placeholder="Enter Contact Person Name"
                                            onChange={(e) =>
                                              setEditContactPersonName(
                                                e.target.value
                                              )
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="form_flex">
                                        <div className="form-group">
                                          <label for="uname">
                                            Activate from
                                          </label>

                                          <input
                                            className="name"
                                            autoComplete="off"
                                            value={
                                              EditActFrom === "0000-00-00" ||
                                                EditActFrom === null
                                                ? "-"
                                                : EditActFrom
                                            }
                                            type="date"
                                            placeholder="Enter Address"
                                            onChange={(e) =>
                                              setEditActFrom(e.target.value)
                                            }
                                            required
                                          />
                                        </div>

                                        <div className="form-group">
                                          <label for="uname">Activate To</label>
                                          <input
                                            className="name"
                                            autoComplete="off"
                                            value={
                                              EditActTo === "0000-00-00" ||
                                                EditActTo === null
                                                ? "-"
                                                : EditActTo
                                            }
                                            type="date"
                                            placeholder="Enter Activate To"
                                            onChange={(e) =>
                                              setEditActTo(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="form_flex">
                                        <div className="form-group">
                                          <label for="uname">
                                            Activate Date
                                          </label>
                                          <input
                                            className="name"
                                            autoComplete="off"
                                            value={
                                              EditActDate === "0000-00-00" ||
                                                EditActDate === null
                                                ? "-"
                                                : EditActDate
                                            }
                                            type="date"
                                            placeholder="Enter Activate To"
                                            onChange={(e) =>
                                              setEditActDate(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label for="uname">
                                            Address
                                            <text className="starm">*</text>
                                          </label>
                                          <input
                                            className="name"
                                            autoComplete="off"
                                            value={EditAddress}
                                            type="text"
                                            placeholder="Enter Address"
                                            textarea
                                            onChange={(e) =>
                                              setEditAddress(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                      </div>

                                      <div className="form_flex">
                                        <div className="form-group">
                                          <label for="uname">Comments</label>

                                          <input
                                            className="name"
                                            autoComplete="off"
                                            value={Edittext}
                                            type="text"
                                            placeholder="Enter Comments"
                                            onChange={(e) =>
                                              setEditText(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="btn_main">
                                        <button
                                          type="submit"
                                          className="btn_save"
                                        >
                                          Save
                                        </button>
                                        <button
                                          type="button"
                                          onClick={clearForm}
                                          className="btn_save btn_cancle"
                                          data-dismiss="modal"
                                        >
                                          Cancel
                                        </button>
                                        {/* <a
                                          type="submit"
                                          className="btn btn-primary btn_client"
                                          onClick={Pdfmaker}
                                        >
                                          PDF
                                        </a> */}
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/********************************************** Delete pop up *********************************/}
                          <div
                            className="modal fade sc_modal"
                            id="exampleModal"
                            tabIndex={-1}
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  />
                                </div>
                                <div className="modal-body">
                                  <h4>Delete Row</h4>
                                  <p>
                                    Are you sure you want to delete this
                                    account?
                                  </p>
                                  <ul>
                                    <li>
                                      <a
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                      >
                                        Cancel
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        data-bs-dismiss="modal"
                                        className="btn btn-danger"
                                        onClick={() => handleDeleteAdmin()}
                                      >
                                        Delete
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/********************************************* View Admin Form *************************************/}
                          <div
                            className="modal fade"
                            id="exampleModalLongEditView"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLongEditView"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog"
                              role="document"
                              style={{ maxWidth: "75%" }}
                            >
                              <div className="modal-content">
                                <div className="popup_modal ">
                                  <button
                                    type="button"
                                    className="close btn_popup"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                  <div className="main_heading">
                                    <h2>View Company</h2>
                                  </div>
                                  <div className="dashed_popup">
                                    <div className="form_flex view_grid">
                                      <div className>
                                        <label for="uname">
                                          Company's Name :
                                        </label>
                                        <h6>{edituserName}</h6>
                                      </div>
                                      <div>
                                        <label for="uname">Email :</label>
                                        <h6>
                                          {EditMail === null ? "-" : EditMail}
                                        </h6>
                                      </div>
                                      <div>
                                        <label for="uname">Mobile No :</label>
                                        <h6>{EditMobileNo}</h6>
                                      </div>
                                    </div>

                                    <div className="form_flex view_grid">
                                      <div>
                                        <label for="uname">Address :</label>
                                        <h6>{EditAddress}</h6>
                                      </div>
                                      <div className>
                                        <label for="uname">
                                          Company's Percentage :
                                        </label>
                                        <h6>{dCompanyPer}</h6>
                                      </div>
                                      <div>
                                        <label for="uname">GST :</label>
                                        <h6>{dGST === null ? "-" : dGST}</h6>
                                      </div>
                                    </div>
                                    <div className="form_flex view_grid">
                                      <div>
                                        <label for="uname ">
                                          Company's Code :
                                        </label>
                                        <h6>{CompanyCode}</h6>
                                      </div>
                                      <div className>
                                        <label for="uname">
                                          Receipt Prefix :
                                        </label>
                                        <h6>{dReceiptPrefix}</h6>
                                      </div>
                                      <div>
                                        <label for="uname">
                                          Invoice Prefix :
                                        </label>
                                        <h6>
                                          {dInvoicePrefix === null
                                            ? "-"
                                            : dInvoicePrefix}
                                        </h6>
                                      </div>
                                    </div>
                                    <div className="form_flex view_grid">
                                      <div>
                                        <label for="uname">
                                          Contact Person Name :
                                        </label>
                                        <h6>{EditContactPersonName}</h6>
                                      </div>
                                      <div>
                                        <label for="uname">
                                          Qualification :
                                        </label>
                                        <h6>
                                          {EditQualification === ""
                                            ? "-"
                                            : EditQualification}
                                        </h6>
                                      </div>
                                      <div>
                                        <label for="uname">Status :</label>
                                        <h6>
                                          {" "}
                                          {Status == 1 ? "Active" : "Deactive"}
                                        </h6>
                                      </div>
                                    </div>

                                    <div className="form_flex view_grid">
                                      <div>
                                        <label for="uname">
                                          Activate From :
                                        </label>
                                        <h6>{formatDate1(EditActFrom)}</h6>
                                      </div>
                                      <div>
                                        <label for="uname">Activate To :</label>
                                        <h6>{formatDate1(EditActTo)}</h6>
                                      </div>
                                      <div>
                                        <label for="uname">
                                          Activate Date :
                                        </label>
                                        <h6>{formatDate1(EditActDate)}</h6>
                                      </div>
                                    </div>
                                    <div className="form_flex view_grid">
                                      <div>
                                        <label for="uname">Company Url :</label>
                                        <h6>{dCompanyUrl}</h6>
                                      </div>
                                    </div>
                                    <div className="Comment_break ">
                                      <div>
                                        <label
                                          for="uname"
                                          className="comment_label"
                                        >
                                          Comments :
                                        </label>
                                        <h6 className="name">
                                          {Edittext === "" ? "-" : Edittext}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      )}
    </>
  );
};

export default ViewUser;
