import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState("password,");
  const [showPassword1, setShowPassword1] = useState("password1,");
  const [showPassword2, setShowPassword2] = useState("password2,");
  const Passform = new FormData();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  {
    /*****************************************Chnge password***************************/
  }
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    Passform.append("OldPassword", oldPassword);
    Passform.append("NewPassword", newPassword);

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    const access_token = localStorage.getItem("access_token");

    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/SuperAdmin/ChangePassword",
        {
          method: "POST",
          headers: {
            Authorization: `${access_token}`,
            ContentType: "multipart/form-data",
          },
          body: Passform,
        }
      );

      const data = await response.json();
      console.log(data);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.result[0].code == 200) {
        toast.success("Password changed successfully");
      } else {
        toast.error("Password change failed");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };
  return (
    <>
      <header className="headerArea">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-xl">
           
            <a className="navbar-brand" href="/#/dashboard">
              <h2
                style={{
                  color: " #7a0000",
                  fontFamily: "Playfair Display",
                  fontWeight: "bold",
                }}y
              >
                Legal CRM
              </h2>
              {/* <img src="assets/img/logo.webp" alt="Logo"/> */}
            </a>
            <h3
              style={{
                fontFamily: "sans-serif",
                color: " #7a0000",
                fontWeight: "bold",
              }}
            >
              Welcome {localStorage.getItem("Name")}
            </h3>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
            <div className="navbar-collapses">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <img src="assets/img/icons/closeWhite.svg" alt="" />
              </button>
            </div>
            <div className="headeRight">
              <div className="settingDrop dropdown">
                <button
                  className="btn"
                  style={{ backgroundColor: "white" }}
                  data-target="#exampleModalLongpass"
                  type="button"
                  data-toggle="modal"
                >
                  <span>
                    
                    <img
                      style={{
                        width: "45px",
                        height: "45px",
                      }}
                      src="assets/img/icons/setting.svg"
                      alt=""
                    />
                  </span>
                </button>
              </div>
              {/* &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; */}
              <div className="userDrop dropdown">
                <button
                  className="dropdown-toggle"
                  type="button"
                  
                >
                  <div className="avatar d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img src="assets/img/avatar.png" alt="Avatar" />
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </nav>
        </div>
        <div
          className="modal fade"
          id="exampleModalLongpass"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ maxWidth: "35%" }}
          >
            <div className="modal-content">
              <div className="popup_modal ">
                <button
                  type="button"
                  className="close btn_popup"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                {/*****************************************Change password***************************/}

                <div className="main_heading">
                  <h2>Change Password</h2>
                </div>
                <div className="dashed_popup">
                  <form role="form " onSubmit={handleSubmit}>
                    <div className="form_flex">
                      <div className="form-group">
                        <label for="uname">Old Password </label>
                        <input
                          autoComplete="off"
                          className="name"
                          id="#password"
                          type={showPassword ? "password" : "text"}
                          placeholder="Enter Password"
                          onChange={(e) => setOldPassword(e.target.value)}
                          required
                        />
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                          onClick={togglePasswordVisibility}
                          className="password-icon"
                        />
                      </div>
                    </div>
                    <div className="form_flex">
                      <div className="form-group">
                        <label for="uname">New Password </label>
                        <input
                          autoComplete="off"
                          className="name"
                          id="#password1"
                          type={showPassword1 ? "password" : "text"}
                          placeholder="Enter Password"
                          onChange={(e) => setNewPassword(e.target.value)}
                          required
                        />
                        <FontAwesomeIcon
                          icon={showPassword1 ? faEyeSlash : faEye}
                          onClick={togglePasswordVisibility1}
                          className="password-icon"
                        />
                      </div>
                    </div>
                    <div className="form_flex">
                      <div className="form-group">
                        <label for="uname">Confirm Password </label>
                        <input
                          autoComplete="off"
                          className="name"
                          id="#password2"
                          type={showPassword2 ? "password" : "text"}
                          placeholder="Enter Password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                        <FontAwesomeIcon
                          icon={showPassword2 ? faEyeSlash : faEye}
                          onClick={togglePasswordVisibility2}
                          className="password-icon"
                        />
                      </div>
                    </div>

                    <div className="btn_main">
                      <button type="submit" className="btn_save">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Header;
