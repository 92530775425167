import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";

import autoTable from "jspdf-autotable";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { download, generateCsv, mkConfig } from "export-to-csv";


import { useNavigate } from "react-router-dom";
const ViewUser = () => {

  /********************************preloader****************************************************/
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    /***********************Simulate an asynchronous operation (e.g., API call) with setTimeout****************************************/
    setTimeout(
      () => {
        setIsLoading(false);
      },
      /**********************************Simulating a 1-second delay*********************************************/
      1000
    );
  }, []);
  const closeButton = document.querySelector(".abs");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".btn-close");
  const navigate = useNavigate();
  /***********************************case delete*****************************************/
  const handleDeleteCaseType = async () => {
    const access_token = localStorage.getItem("access_token");
    formData.append("ID", delId);
    formData.append("DeletedBy", localStorage.getItem("id"));
    try {
      const response = await fetch(
        " https://api.lawyerhunt.in/api/Master/DeleteCaseType",
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `${access_token}`,
            Accept: "*/*",
            ContentType: "multipart/form-data",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }
      /******************************* Handle the response from the server*********************************/
      const data = await response.json();
      console.log(data.result.message);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.result.code === 200) {
        getCountries();
        closeDelete.click();
        toast.success("Data Deleted !");
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  /*********************************Add Case Type****************************************/

  const formData = new FormData();
  const [delId, setDelId] = useState();
  const [CaseType, setCaseType] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.append("CaseType", CaseType);
    formData.append("CourtID", selectedOption);
    formData.append("CreatedBy", localStorage.getItem("id"));
    /*******************Send the formData to the server using an API call or fetch()*****************************************/
    const access_token = localStorage.getItem("access_token");

    try {
      const response = await fetch(
        " https://api.lawyerhunt.in/api/Master/AddCaseType",
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `${access_token}`,
            Accept: "*/*",
            ContentType: "multipart/form-data",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }
      /*****************************Handle the response from the server***********************************************/
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.result[0].message === "Success") {
        getCountries();
        clearForm();
        closeButton.click();
        toast.success("Case Type Added !");
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  /*****************************Edit Case*******************************************/
  const EditAdmin = new FormData();
  const [EditID, setEditID] = useState();
  const [EditCaseType, setEditCaseType] = useState();
  const [EditCourtName, setEditCourtName] = useState("");
  const [EditCourtID, setEditCourtID] = useState("");
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    EditAdmin.append("ID", EditID);
    EditAdmin.append("CaseType", EditCaseType);
    EditAdmin.append("CourtID", EditselectedOption);
    EditAdmin.append("UpdatedBy", localStorage.getItem("id"));

    /**********************Send the formData to the server using an API call or fetch()****************************/

    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/Master/EditCaseType",
        {
          method: "POST",
          body: EditAdmin,
          headers: {
            Authorization: localStorage.getItem("access_token"),
            Accept: "*/*",
            ContentType: "multipart/form-data",
          },
        }
      );
      const data1 = await response.json();
      console.log(data1);
      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }
      /**********************************Handle the response from the server***********************************/

      console.log(data1.result[0].Id);
      setEditID(data1.result[0].Id);
      setEditCaseType(data1.result[0].CaseType);
      setEditCourtName(data1.result[0].CourtName);
      setEditCourtID(data1.result[0].CourtID);
      if (data1 && data1.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }
      if (data1.result[0].message === "Success") {
        getCountries();
        closeEdit.click();
        toast.success("Case Type Updated !", {
          position: "top-right",
          /***********************************Simulating a 2-second delay***************************************/
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(data1.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  /***********************************view case type by  id******************************************/
  const adminById = new FormData();

  const updateUser = async (id) => {
    adminById.append("ID", id);

    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/Master/ViewCaseTypeById",
        {
          method: "POST",
          body: adminById,

          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setEditID(data.result[0].Id);
      setEditCaseType(data.result[0].CaseType);
      setEditSelectedOption(data.result[0].CourtID);

      setIsStatus(data.result[0].Status);
    } catch (error) {
      console.error(error);
    }
  };
  /*********************************View Case************************************/
  const [search, setSearch] = useState("");
  const [filteredcountries, setFilteredCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const getCountries = async () => {
    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/Master/ViewCaseType",
        {
          method: "POST",

          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );

      const data = await response.json();

      setCountries(data.result);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setFilteredCountries(data.result);
    } catch (error) {
      console.error(error);
    }
  };
  const [isStatus, setIsStatus] = useState();
  const deActive = async (row) => {
    const deactiveId = new FormData();
    deactiveId.append("AdminID", `${localStorage.getItem("id")}`);
    deactiveId.append("ID", row.Id);
    deactiveId.append("Status", row.Status == "1" ? "0" : "1");
    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/Master/CaseTypeStatus",
        {
          method: "POST",
          body: deactiveId,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );
    
      const data = await response.json();

      if (data && data.result.message === "Status Updated Successfully") {
        if (row.Status === "1") {
         toast.error("Status Deactivated");
        } else{
         toast.success("Status Activated");
        }
        getCountries();
       }
     }
     catch (error) {
       console.error(error);
     }
   };


  const customStyles = {
    cells: {
      style: {
        fontSize: "14px",
      },
    },

    headRow: {
      style: {
        color: "white",
        fontSize: "13px",
        backgroundColor: "#7a0103",
        borderRadius: "10px 10px 0px 0px",
      },
    },
  };


  

      //---------- Add DataTable By Kiran , date 21-11-23-------------
                 
                 
                 const excelData =
                 countries &&
                 countries.map((obj) => {
                   const { Id, Status, CourtID, code, message, AdminID, ...rest } = obj;
                   return rest;
                 });
                 
  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });
  
  const formattedDate = new Date().toISOString().slice(0, 10); // Get today's date
    const filename = `Bank|LegalCRM__${formattedDate}.csv`;
  
    const csv = generateCsv(csvConfig)(excelData);
    download(csvConfig)(csv);
  };



  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const selectedColumnIds = ['CaseType','CourtName'];
  
    // Add a serial number column
    const columnsToExport = [
      { id: 'serialNo', header: 'S No.' }, // Add this line
      ...columns.filter((column) => selectedColumnIds.includes(column.id)),
    ];
  
    const tableHeaders = columnsToExport.map((c) => c.header);
  
    const formattedDate = new Date().toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  
    const filename = `Court|LegalCRM_${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("Name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      // Add a heading to the first page
      doc.text('Case Type', 86, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
        const formattedDateInPdf = row.original["Date"]
            ? formData(new Date(row.original["Date"]), "dd/MM/yyyy")
            : "";
      return [
        { content: (index + 1).toString(), styles: { fontStyle: 'bold' } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          if (column.id === "Date") {
              return formattedDateInPdf;
          }

          const value =
              column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
      }),
  ];
});

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY:watermarkHeight +5
    });
  
    // Add a heading to the table
   
   // Save the PDF to a Blob
   const pdfBlob = doc.output('blob');

   // Create a blob URL
   const blobUrl = URL.createObjectURL(pdfBlob);
 
   // Open the URL in a new tab
   window.open(blobUrl, '_blank');
 
   // Optionally revoke the object URL after opening the new tab
   URL.revokeObjectURL(blobUrl);
    doc.save(filename);
  };
     
    
  //               -----------Column  add by kiran ,date 21-11-23-------------
      const columnHelper = createMRTColumnHelper();
      const columns = [
    columnHelper.accessor("name", {
      header: "Action",
      minSize: "10px",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => updateUser(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
            

                <span>
                <a
                  // style={{
                  //   cursor: "pointer",
                  //   display: isStatus === "0" ? "none" : "block",
                  // }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>

                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              
            </div>
          </>
        );
      },
    }),
    
    

    columnHelper.accessor("CaseType", {
      header: "Case Type",
      Cell: (row) => {
        return (
          <div>
            {row.row.original. CaseType === null ||
            row.row.original. CaseType === ""
              ? "-"
              : row.row.original. CaseType}
          </div>
        );
      },
    }),


    columnHelper.accessor("CourtName", {
      header: "Court Name",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CourtName === null ||
            row.row.original.CourtName === ""
              ? "-"
              : row.row.original.CourtName}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return (
          <div>{row.row.original.Status === "1" ? "Active" : "DeActive"}</div>
        );
      },
    }),

   
  ];

         //  --------------Data Table Kiran,date 21-11-23 ---------------
  const table = useMaterialReactTable({
    columns,
    data: countries,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    
    enableStickyHeader: true,
    enableRowNumbers: true,
    rowNumberDisplayMode: 'original',
    muiPaginationProps: {
      // rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export PDF
        </Button>
      </Box>
      // <Box
      //   sx={{
      //     display: "flex",
      //     gap: "16px",
      //     padding: "8px",
      //     flexWrap: "wrap",
      //   }}
      // ></Box>
    ),
          });


  



  const [Courts, setCourts] = useState([]);
  const [activeCourts, setActiveCourts] = useState([]);
  const getCourt = async () => {
    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/Master/ViewCourt",
        {
          method: "POST",

          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );

      const data2 = await response.json();
      setCourts(data2.result);

      setActiveCourts(data2.result.filter((court) => court.Status === "1"));
      if (data2.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    getCountries();
    getCourt();
  }, []);
  useEffect(() => {
    const result = countries.filter((country) => {
      return country.CaseType.toLowerCase().match(search.toLowerCase());
    });

    setFilteredCountries(result);
  }, [search]);
  function clearForm() {
    var fields = document.getElementsByClassName("name");
    for (var i = 0; i < fields.length; i++) {
      fields[i].value = "";
    }
  }
  const [selectedOption, setSelectedOption] = useState("");
  const [EditselectedOption, setEditSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleOptionChange1 = (event) => {
    setEditSelectedOption(event.target.value);
  };
  return (
    <>
      {/* {isLoading ? (
        <Preloader />
      ) : ( */}
      <div>
        <div className="wraper">
          <Header />
          <div className="mainWraper">
           
            <Sidebar />

            <div className="pageBody">
                <div className="pageContent">
                  
                        <div className="PageTitle">
                        <h3>Case Type List</h3>
                        <a
                          type="button"
                          className="btn btn-primary btn_client"
                          data-toggle="modal"
                          data-target="#exampleModalLong"
                        >
                           Add Case Type
                          <img src="assets/img/icons/add.svg" alt="" />
                         
                        </a>
                      </div>
                      <div className="searDesign">
                        {countries &&
                        countries.length > 0 &&
                        countries[0].message === "Data not found" ? (
                          <h3 className="visuald">
                            There are no records to display
                          </h3>
                        ) : (
                          <>
                            
                                 {/* -------------- DataTable by kiran-----------  */}

                        <MaterialReactTable table={table} />
                        </>
                            )}
                        {/*****************************************Add Case Type***************************/}
                        <div
                          className="modal fade"
                          id="exampleModalLong"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLongTitle"
                          aria-hidden="true"
                        >
                          <div
                            className="modal-dialog"
                            role="document"
                            style={{ maxWidth: "50%" }}
                          >
                            <div className="modal-content">
                              <div className="popup_modal ">
                                <button
                                  type="button"
                                  className="close btn_popup abs"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="main_heading">
                                  <h2>Add Case Type</h2>
                                </div>
                                <div className="dashed_popup">
                                  <form role="form " onSubmit={handleSubmit}>
                                    <div className="form_flex">
                                      <div className="form-group">
                                        <label for="uname">
                                          Case Type{" "}
                                          <text className="starm">*</text>{" "}
                                        </label>
                                        <input
                                          autoComplete="off"
                                          className="name"
                                          type="text"
                                          placeholder="Enter Case Type Name"
                                          onChange={(e) =>
                                            setCaseType(e.target.value)
                                          }
                                          required
                                        />
                                      </div>
                                      <div className="form-group">
                                        <div>
                                          <label htmlFor="courtSelect">
                                            Courts
                                            <text className="starm">*</text>
                                          </label>
                                          <select
                                            className="name"
                                            id="courtSelect"
                                            value={selectedOption}
                                            onChange={handleOptionChange}
                                            required
                                          >
                                            <option>--Please Select--</option>

                                            {activeCourts.map((court) => (
                                              <option
                                                key={court.Id}
                                                value={court.Id}
                                              >
                                                {court.CourtName}
                                              </option>
                                            ))}
                                            {/* {Courts && Courts.length > 0 ? (
                                              Courts.map((val) => (
                                                <option
                                                  key={val.Id}
                                                  value={val.Id}
                                                >
                                                  {val.CourtName}
                                                </option>
                                              ))
                                            ) : (
                                              <option value="">
                                                Loading...
                                              </option>
                                            )} */}
                                          </select>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="btn_main">
                                      <button
                                        type="submit"
                                        className="btn_save"
                                      >
                                        Save
                                      </button>
                                      <button
                                        type="button"
                                        onClick={clearForm}
                                        className="btn_save btn_cancle"
                                        data-dismiss="modal"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*******************************Edit Case Type*****************************/}
                        <div
                          className="modal fade"
                          id="exampleModalLongEdit"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLongTitle"
                          aria-hidden="true"
                        >
                          <div
                            className="modal-dialog"
                            role="document"
                            style={{ maxWidth: "50%" }}
                          >
                            <div className="modal-content">
                              <div className="popup_modal ">
                                <button
                                  type="button"
                                  className="e_popup close btn_popup"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="main_heading">
                                  <h2>Edit Case Type</h2>
                                </div>
                                <div className="dashed_popup">
                                  <form
                                    role="form "
                                    onSubmit={handleEditSubmit}
                                  >
                                    <div className="form_flex">
                                      <div className="form-group">
                                        <label for="uname">
                                          Case Type
                                          <text className="starm">*</text>
                                        </label>
                                        <input
                                          value={EditCaseType}
                                          autoComplete="off"
                                          className="name"
                                          type="text"
                                          placeholder="Enter Case Type Name"
                                          onChange={(e) =>
                                            setEditCaseType(e.target.value)
                                          }
                                          required
                                        />
                                      </div>
                                      <div className="form-group">
                                        <div>
                                          <label for="uname">
                                            Courts
                                            <text className="starm">
                                              *
                                            </text>{" "}
                                          </label>
                                          <select
                                            className="name"
                                            value={EditselectedOption}
                                            onChange={handleOptionChange1}
                                            required
                                          >
                                            <option>--Please Select--</option>
                                            {Courts && Courts.length > 0 ? (
                                              Courts.map((val) => (
                                                <option
                                                  key={val.Id}
                                                  value={val.Id}
                                                >
                                                  {val.CourtName}
                                                </option>
                                              ))
                                            ) : (
                                              <option value="">
                                                Loading...
                                              </option>
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="btn_main">
                                      <button
                                        type="submit"
                                        className="btn_save"
                                      >
                                        Update
                                      </button>
                                      <button
                                        type="button"
                                        onClick={clearForm}
                                        className="btn_save btn_cancle"
                                        data-dismiss="modal"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/****************************************Delete pop up************************************/}
                        <div
                          className="modal fade sc_modal"
                          id="exampleModal"
                          tabIndex={-1}
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                />
                              </div>
                              <div className="modal-body">
                                <h4>Delete Row</h4>
                                <p>
                                  Are you sure you want to delete this CaseType?
                                </p>
                                <ul>
                                  <li>
                                    <a
                                      className="btn btn-secondary"
                                      data-bs-dismiss="modal"
                                    >
                                      Cancel
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      data-bs-dismiss="modal"
                                      className="btn btn-danger"
                                      onClick={() => handleDeleteCaseType()}
                                    >
                                      Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                   
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      {/* )} */}
    </>
  );
};

export default ViewUser;
