import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const DeleteCaseType = createApi({
  reducerPath: "DeleteCaseType",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.lawyerhunt.in/api/Master/DeleteCaseType",

    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        headers.set("Authorization", `${token}`);
        headers.set("Accept", "*/*");
      }
    },
  }),

  endpoints: (builder) => ({
    getDeleteCaseType: builder.query({
      query: (Id) => {
        const formData = new FormData();
        formData.append("ID", Id);
        formData.append("DeletedBy",localStorage.getItem("id"))
        return {
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const { useGetDeleteCaseTypeQuery } = DeleteCaseType;

export default DeleteCaseType;
