import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const DeleteCourt = createApi({
  reducerPath: "DeleteCourt",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.lawyerhunt.in/api/Master/DeleteCourt",

    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        headers.set("Authorization", `${token}`);
        headers.set("Accept", "*/*");
      }
    },
  }),

  endpoints: (builder) => ({
    getDeleteCourt: builder.query({
      query: (Id) => {
        const formData = new FormData();
        formData.append("ID", Id);
        formData.append("DeletedBy",localStorage.getItem("id"))
        return {
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const { useGetDeleteCourtQuery } = DeleteCourt;

export default DeleteCourt;
