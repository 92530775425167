import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const EditAdminApi = createApi({
  reducerPath: "EditAdminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.lawyerhunt.in/api/SuperAdmin/EditAdmin",

    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");

      if (token) {
        headers.set("Authorization", `${token}`);
        headers.set("Accept", "*/*");
      }
    },
  }),

  endpoints: (builder) => ({
    getEditAdmin: builder.query({
      query: (credentials) => {
        return {
          method: "POST",
          body: credentials,
        };
      },
    }),
  }),
});

export const { useGetEditAdminQuery } = EditAdminApi;

export default EditAdminApi;
