import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
const Login = () => {
  const navigate = useNavigate();
  const formData = new FormData();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("password");
  function PasswordInput() {
    const [showPassword, setShowPassword] = useState(false);
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  
  const handleCaptchaChange = (value) => {
    setIsCaptchaValid(!!value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (userName.length <=1) {
      toast.error("Invalid UserName");
    } else {
      formData.append("UserName", userName);
    }
    if (password.length <=1) {
      toast.error("Invalid Password");
    } else {
      formData.append("Password", password);
    }
    
    /**********************Send the formData to the server using an API call or fetch()****************************/
    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/SuperAdmin/login",
        {
          method: "POST",
          body: formData,
        }
      );
      /*****************************Handle the response from the server***********************************************/
      const data = await response.json();
      localStorage.setItem("access_token", data.result[0].access_token);
      localStorage.setItem("id", data.result[0].Id);
      localStorage.setItem("Name", data.result[0].Name);
      if (userName.trim() === "" || password.trim() === "") {
        toast.error("Username and password are required");
      }
      if (!isCaptchaValid){
        toast.error("Please verify first through the captcha")
      }
      if (data.result[0].access_token) {
        toast.success(`Welcome ${data.result[0].Name}`);
        navigate("/dashboard");
      } else {
        toast.error("INVALID USERNAME OR PASSWORD");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div className="wraper">
        <section className="loginbgPicture">
          <div className="container">
            <div className="row ">
              <div className="col-md-4">
                <div className="login_pg">
                  <div className="login_Logo">
                    <figure>
                      <a href="/">
                        <img src="assets/img/logo.webp" alt="" />
                      </a>
                    </figure>
                  </div>
                  <div className="myHeading">
                    <h2>Sign In</h2>
                  </div>
                  {/*****************************Login form***********************************************/}

                  <form className="bannerForm " onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12 col-md-12  col-sm-12">
                        <div className="form-group">
                          <span>
                            <img src="assets/img/icons/user.svg" alt="" />
                          </span>
                          <input
                            placeholder="User ID"
                            id="my-input"
                            className="form-control"
                            type="email"
                           
                            onChange={(e) => setUserName(e.target.value)}
                           required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12  col-sm-12">
                        <div className="form-group">
                          <span>
                            <img src="assets/img/icons/password.svg" alt="" />
                          </span>
                          <input
                            className="form-control"
                            autoComplete="off"
                            type={showPassword ? "password" : "text"}
                            placeholder="Password"
                            id="#password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />

                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            onClick={togglePasswordVisibility}
                            style={{
                              paddingTop: "-15px",
                              position: "absolute",
                              top: "19px",
                              right: "10px",
                            }}
                            className="password-icon"
                          />
                        </div>
                      </div>
                      <div>
                        {/****************************Captcha *********************************/}
                        {/* <ReCAPTCHA sitekey="6LfwG4EnAAAAAGKT9zmV396Cg9TI8vEijWDSzCJU" onChange={handleCaptchaChange} /> */}
                      </div>

                      <div className="col-lg-12  col-sm-12">
                        <div className="btn_login">
                          <br />
                          {/* <button href="/" type="submit" disabled={!isCaptchaValid}>
                            Login
                          </button> */}
                          <button href="/" type="submit">
                            Login
                          </button>
                        </div>
                      </div>
                      <div className="privacy_plcy">
                        <p>
                          This site is protected by reCAPTCHA and the Google
                          Privacy Policy.
                        </p>
                      </div>
                      <div className="col-lg-12 col-sm-12"></div>
                    </div>
                  </form>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
