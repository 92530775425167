
// import React, { useState } from "react";
// import styled from "styled-components";
// import { Link } from "react-router-dom";
// import * as FaIcons from "react-icons/fa";
// import * as AiIcons from "react-icons/ai";
// import * as MdIcons from "react-icons/md";

// import SubMenu from "./SubMenu";
// import { IconContext } from "react-icons/lib";
// import * as RiIcons from "react-icons/ri";
// import * as IoIcons from "react-icons/io";
// import { BiCalendar } from "react-icons/bi";
// import { RxActivityLog } from "react-icons/rx";
// import { useNavigate } from "react-router-dom";
// import { FaBars }   from "react-icons/fa";

// const Nav = styled.div`
//   background: #fffff;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   position: absolute;
//   top: 12px;
//   z-index: 1;
// `;

// const NavIcon = styled(Link)`
//   margin-left: 1rem;
//   font-size: 2rem;
//   display: flex;
//   justify-content: end;
//   align-items: center;
// `;

// const SidebarNav = styled.nav`
//   overflow:scroll,
//   background: #ffff;
//   width: 250px;
//   height: 100vh;
//   display: flex;
//   justify-content: center;
//  position :fixed;
//   top: 0;
//   left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
//   transition: 350ms;
//   z-index: 9;
// `;
// const icon = styled.div`
//   color: #000 !important ;
// `;
// const SidebarWrap = styled.div`
//   width: 100%;
// `;

// const SidebarData = [
//   {
//     title: "Dashboard",
//     path: "/dashboard ",
//     icon: <AiIcons.AiFillDashboard />,
//     iconClosed: <RiIcons.RiArrowDownSFill />,
//     iconOpened: <RiIcons.RiArrowUpSFill />,
//   },
//   {
//     title: "Manage Company",
//     path: " ",
//     icon: <FaIcons.FaUsers />,
//     iconClosed: <RiIcons.RiArrowDownSFill />,
//     iconOpened: <RiIcons.RiArrowUpSFill />,

//     subNav: [
//       {
//         title: "Manage Company",
//         path: "/view-company",
//         icon: <FaIcons.FaUserTie />,
//       },
//     ],
//   },
//   {
//     title: "Manage Master",
//     path: " ",
//     icon: <FaIcons.FaIdBadge />,
//     iconClosed: <RiIcons.RiArrowDownSFill />,
//     iconOpened: <RiIcons.RiArrowUpSFill />,

//     subNav: [
//       {
//         title: "Manage Court",
//         path: "/view-court",
//         icon: <FaIcons.FaLandmark />,
//       },
//       {
//         title: "Manage City Court",
//         path: "/view-city-court",
//         icon: <FaIcons.FaLandmark />,
//       },
//       {
//         title: "Manage Case Type",
//         path: "/view-case-type",
//         icon: <FaIcons.FaBalanceScale />,
//       },
//       {
//         title: "Manage Year",
//         path: "/view-year",
//         icon: <BiCalendar />,
//       },

//       {
//         title: "Manage Activity",
//         path: "/view-activity",
//         icon: <RxActivityLog />,
//       },
//     ],
//   },
// ];

// const Sidebar = () => {
//   const closeDelete = document.querySelector(".btn-close");

//   const navigate = useNavigate();
//   const Logout = () => {
//     navigate("/");
//     closeDelete.click();
//     localStorage.clear();
//     window.location.reload();
//   };
//   const [sidebar, setSidebar] = useState(false);

//   // const [SidebarData, setSidebarData] = useState([]);
//   const showSidebar = () => setSidebar(!sidebar);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   // const toggleSidebar = () => {
//   //   setIsSidebarOpen(!isSidebarOpen);
//   // };
//   // const toggleSidebar1 = () => {
//   //   setIsSidebarOpen(isSidebarOpen);
//   // };

//   return (
//     <>
//       <IconContext.Provider value={{ color: "#000" }}>
//         <div className="navbarmy">
//          <Nav>
//               <NavIcon to="#">
             
//                <img className="BarsImage"
//                 src="assets/img/icons/Bars.svg" 
              
//                 />
//               </NavIcon>
//             </Nav>
            
//             <SidebarNav className="customsidenav" sidebar={sidebar}>
//             <SidebarWrap>
//               <NavIcon to="#">
//                 <AiIcons.AiOutlineClose onClick={showSidebar} 
//                 style={{ height: "25px", width: "25px" }}
//                 />
//               </NavIcon>

//               {SidebarData.map((item, index) => {
//                 return <SubMenu item={item} key={index} />;
//               })}
              
//               <button
//                 type="button"
//                 className="btn btn-primary logout_my "
//                 data-bs-toggle="modal"
//                 data-bs-target="#staticBackdrop"
//               >
//                 <span className="logoutlogo">
//                   <img src="assets/img/icons/logout.svg" alt="" />
//                 </span>{" "}
//                 Logout
//               </button>

//               <div
//                 className="modal fade"
//                 id="staticBackdrop"
//                 data-bs-backdrop="static"
//                 data-bs-keyboard="false"
//                 tabIndex={-1}
//                 aria-labelledby="staticBackdropLabel"
//                 aria-hidden="true"
//               >
//                 <div className="modal-dialog">
//                   <div className="modal-content">
//                     <div className="modal-header ">
//                       <button
//                         type="button"
//                         className="btn-close"
//                         data-bs-dismiss="modal"
//                         aria-label="Close"
//                       ></button>
//                     </div>

//                     <div className="modal-body modal_logout ">
//                       <h4>Logout</h4>
//                       <p>Are you sure want to log out ?</p>
//                     </div>

//                     <div className="modal-footer">
//                       <button
//                         type="button"
//                         className="btn btn-secondary"
//                         data-bs-dismiss="modal"
//                       >
//                         Close
//                       </button>
//                       <button
//                         type="button"
//                         className="btn btn-danger"
//                         onClick={() => Logout()}
//                       >
//                         Logout
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </SidebarWrap>
//           </SidebarNav>
//         </div>
//       </IconContext.Provider>
//     </>
//   );
// };

// export default Sidebar;


// ---------Added By Kiran----------Date-4-12-23----


import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import * as RiIcons from "react-icons/ri";
import * as IoIcons from "react-icons/io";
import { BiCalendar } from "react-icons/bi";
import { RxActivityLog } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

const Nav = styled.div`
  background: #15171c;
  height: 80px;
  display: none !important;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: none !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #ffffff;
  width: 270px;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 20px 8px;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: -1;
`;
const icon = styled.div`
  color: #000 !important ;
`;
const SidebarWrap = styled.div`
  width: 100%;
`;

const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard ",
    icon: <AiIcons.AiFillDashboard />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Manage Company",
    path: " ",
    icon: <FaIcons.FaUsers />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Manage Company",
        path: "/view-company",
        icon: <FaIcons.FaUserTie />,
      },
    ],
  },
  {
    title: "Manage Master",
    path: " ",
    icon: <FaIcons.FaIdBadge />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Manage Court",
        path: "/view-court",
        icon: <FaIcons.FaLandmark />,
      },
      {
        title: "Manage City Court",
        path: "/view-city-court",
        icon: <FaIcons.FaLandmark />,
      },
      {
        title: "Manage Case Type",
        path: "/view-case-type",
        icon: <FaIcons.FaBalanceScale />,
      },
      {
        title: "Manage Year",
        path: "/view-year",
        icon: <BiCalendar />,
      },

      {
        title: "Manage Activity",
        path: "/view-activity",
        icon: <RxActivityLog />,
      },
    ],
  },
];

const Sidebar = () => {
  const closeDelete = document.querySelector(".btn-close");

  const navigate = useNavigate();
  const Logout = () => {
    navigate("/");
    closeDelete.click();
    localStorage.clear();
    window.location.reload();
  };
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  return (
    <>
      <IconContext.Provider value={{ color: "#000" }}>
        <div className="navbarmy">
          <Nav>
            <NavIcon to="#">
              <FaIcons.FaBars onClick={showSidebar} />
            </NavIcon>
          </Nav>
          <SidebarNav sidebar={sidebar}>
            <SidebarWrap>
              <NavIcon to="#">
                <AiIcons.AiOutlineClose onClick={showSidebar} />
              </NavIcon>

              {SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
              {/* <subNav> */}

              {/*********************************** Logout pop up *******************************/}

              {/* </subNav> */}

              <button
                type="button"
                className="btn btn-primary logout_my "
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                <span className="logoutlogo">
                  <img src="assets/img/icons/logout.svg" alt="" />
                </span>{" "}
                Logout
              </button>

              <div
                className="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header ">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>

                    <div class="modal-body modal_logout ">
                      <h4>Logout</h4>
                      <p>Are you sure want to log out ?</p>
                    </div>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => Logout()}
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </SidebarWrap>
          </SidebarNav>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
