import React from "react";
import { BiSolidErrorAlt } from "react-icons/bi";
const Error = () => {
  return (
    <>
    {/* Icons */}
        <BiSolidErrorAlt style={{height:'100px',width:'100px'}} />
     
      <h1>404 Page Not Found</h1>
      <h2>
        <a href="/">Go back to home page.</a>
      </h2>
    </>
  );
};

export default Error;
