import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { useNavigate } from "react-router-dom";
import { ToastHeader } from "react-bootstrap";
const Year = () => {
  /***************Form CLose and clear************************/
  const navigate = useNavigate();
  const closeButton = document.querySelector(".ads");
  const closeDelete = document.querySelector(".btn-close");
  function clearForm() {
    var fields = document.getElementsByClassName("name");
    for (var i = 0; i < fields.length; i++) {
      fields[i].value = "";
    }
  }
  const [Year, setYear] = useState("");
  const [isValid, setIsValid] = useState(true);
  const currentYear = new Date().getFullYear();
  const handleInputChange = (e) => {
    const value = e.target.value;
    // Use regular expression to check if the input contains only numbers
    const isValidInput = /^\d{4}$/.test(value);

    if (isValidInput) {
      const yearInt = parseInt(value, 10); // Parse input as integer
      if (yearInt >= 1950 && yearInt <= currentYear) {
        setIsValid(true);
      } else {
        setIsValid(false);
        toast.error(`Year must be between 1950 and ${currentYear}`);

      }
    } else {
      setIsValid(false);
    }
    setIsValid(isValidInput);
    setYear(value);
  };
  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    // Allow only numeric characters and backspace
    if (charCode < 48 || charCode > 57 || e.target.value.length >= 4) {
      e.preventDefault();
    }
  };
  /******************************add*********************************************/
  const formData = new FormData();
  const [delId, setDelId] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid) {
      const yearInt = parseInt(Year, 10); // Parse input as integer
      if (yearInt >= 1950 && yearInt <= currentYear) {
        formData.append("Year", yearInt);
      } else {
        toast.error("Invalid Year");
      }
    } else {
      clearForm();
      toast.error("Invalid Year");
    }
    // if (Year.length === 4||Year > "1950" || Year <= currentYear) {
    //   formData.append("Year", Year);
    // } else {
    //   toast.error("Invalid Year");
    // }

    /***************************Send the formData to the server using an API call or fetch()***************************************/
    const access_token = localStorage.getItem("access_token");

    try {
      const response = await fetch(
        " https://api.lawyerhunt.in/api/Master/AddYear",
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `${access_token}`,
            Accept: "*/*",
            ContentType: "multipart/form-data",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }
      /******************************Handle the response from the server**************************************/
      const data = await response.json();
      console.log(data.result[0].message);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.result[0].code === "200") {
        getCountries();
        clearForm();
        closeButton.click();
        toast.success("Year Added !");
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /*****************************************handle Delete Year******************************************************************/

  const [DeleteID, setDeleteID] = useState("");

  const handleDeleteYear = async () => {
    const access_token = localStorage.getItem("access_token");
    formData.append("ID", delId);
    try {
      const response = await fetch(
        " https://api.lawyerhunt.in/api/Master/DeleteYear",
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `${access_token}`,
            Accept: "*/*",
            ContentType: "multipart/form-data",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }
      /******************************* Handle the response from the server*********************************/

      const data = await response.json();
      console.log(data.result.message);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.result.code === 200) {
        getCountries();
        closeDelete.click();
        toast.success("Year Deleted !");
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  /******************************* View*********************************/

  const [search, setSearch] = useState("");
  const [filteredcountries, setFilteredCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const getCountries = async () => {
    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/Master/ViewYear",
        {
          method: "POST",

          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );

      const data = await response.json();

      setIsStatus(data.result.Status);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }

      if (data && data.result[0].code == 200) {
        setCountries(data.result);
      }
      setFilteredCountries(data.result);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getCountries();
  }, []);
  useEffect(() => {
    const result = countries.filter((country) => {
      return country.Year.toLowerCase().match(search.toLowerCase());
    });
    setFilteredCountries(result);
  }, [search]);

  /***************************Status api call*************************/
  const [isStatus, setIsStatus] = useState();
  const deActive = async (row) => {
    const deactiveId = new FormData();
    deactiveId.append("AdminID", `${localStorage.getItem("id")}`);
    deactiveId.append("ID", row.Id);
    deactiveId.append("Status", row.Status == "1" ? "0" : "1");
    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/Master/YearStatus",
        {
          method: "POST",
          body: deactiveId,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );
      
      const data = await response.json();

      if (data && data.result.message === "Status Updated Successfully") {
        if (row.Status === "1") {
         toast.error("Status Deactivated");
        } else{
         toast.success("Status Activated");
        }
        getCountries();
       }
     }
     catch (error) {
       console.error(error);
     }
   };


   
  const customStyles = {
    cells: {
      style: {
        fontSize: "14px",
      },
    },

    headRow: {
      style: {
        color: "white",
        fontSize: "13px",
        backgroundColor: "#7a0103",
        borderRadius: "10px 10px 0px 0px",
      },
    },
  };


                 // Add DataTable By Kiran
                 
                 
                 const excelData =
                 countries &&
                 countries.map((obj) => {
                   const { Id, Status, code, message, AdminID, ...rest } = obj;
                   return rest;
                 });
                 
  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });
  
  const formattedDate = new Date().toISOString().slice(0, 10); // Get today's date
    const filename = `Bank|LegalCRM__${formattedDate}.csv`;
  
    const csv = generateCsv(csvConfig)(excelData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const selectedColumnIds = ['Year'];
  
    // Add a serial number column
    const columnsToExport = [
      { id: 'serialNo', header: 'S No.' }, // Add this line
      ...columns.filter((column) => selectedColumnIds.includes(column.id)),
    ];
  
    const tableHeaders = columnsToExport.map((c) => c.header);
  
    const formattedDate = new Date().toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  
    const filename = `Court|LegalCRM_${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("Name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      return [
        { content: (index + 1).toString(), styles: { fontStyle: 'bold' } }, // Serial number
        ...columnsToExport.slice(1).map((column) => { // Exclude the first column (serial number) when mapping
          const value = column.id in row.original ? row.original[column.id] : '';
          return value !== null && value !== undefined ? value : '';
        }),
      ];
    });
  
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY:watermarkHeight +10

    });
  
    // Add a heading to the table
    doc.text('Year List',84, 20);
   // Save the PDF to a Blob
   const pdfBlob = doc.output('blob');

   // Create a blob URL
   const blobUrl = URL.createObjectURL(pdfBlob);
 
   // Open the URL in a new tab
   window.open(blobUrl, '_blank');
 
   // Optionally revoke the object URL after opening the new tab
   URL.revokeObjectURL(blobUrl);
    doc.save(filename);
  };
     
    
  //               -----------Column  add by kiran ,date 21-11-23-------------
      const columnHelper = createMRTColumnHelper();
      const columns = [
    columnHelper.accessor("name", {
      header: "Action",
      minSize: "10px",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              
            <span>
                <a
                  // style={{
                  //   cursor: "pointer",
                  //   display: isStatus === "0" ? "none" : "block",
                  // }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              
            </div>
          </>
        );
      },
    }),

    columnHelper.accessor("Year", {
      header: "Year",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Year === null ||
            row.row.original.Year === ""
              ? "-"
              : row.row.original.Year}
          </div>
        );
      },
    }),


    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return (
          <div>{row.row.original.Status === "1" ? "Active" : "DeActive"}</div>
        );
      },
    }),

   
  ];

         //  --------------Data Table Kiran ---------------
      const table = useMaterialReactTable({
    columns,
    data: countries,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    
    enableStickyHeader: true,
    enableRowNumbers: true,
    rowNumberDisplayMode: 'original',
    muiPaginationProps: {
      // rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export PDF
        </Button>
      </Box>
      // <Box
      //   sx={{
      //     display: "flex",
      //     gap: "16px",
      //     padding: "8px",
      //     flexWrap: "wrap",
      //   }}
      // ></Box>
    ),
          });






  return (
    <>
      <div>
        <div className="wraper">
          <Header />
          <div className="mainWraper">
            <div className="menuOverlay"></div>
            <Sidebar />

            <div className="dashboradcontainer">
              <div className="accountContainer userListContainer">
                <div className="create_account_box userlistBox">
                  <div className="userListTable">
                    <div className="tableBox">
                      <div className="tableShortby">
                        <h3 className="fz30 dashTitle">Year List</h3>
                        <a
                          type="button"
                          className="btn btn-primary btn_client"
                          data-toggle="modal"
                          data-target="#exampleModalLong"
                        >
                          <img src="assets/img/icons/add.svg" alt="" />
                          Add Year
                        </a>
                      </div>
                      <div className="searDesign">
                        {/* {countries[0].message == "Success" ? ( */}
                        {/* {countries[0].message == "Success" ? ( 
                            <> */}
                        {countries && countries.length > 0 && countries[0].message === "Data not found" ? (
                          <h3 className="visuald">
                            There are no records to display
                          </h3>
                        ) : (
                          <>
                         
                                           {/* -------------- DataTable by kiran-----------  */}

                        <MaterialReactTable table={table} />
                        </>
                            )}

                      {/************************************************************** Add ****************************************/}
                      <div
                        className="modal fade"
                        id="exampleModalLong"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLongTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog"
                          role="document"
                          style={{ maxWidth: "40%" }}
                        >
                          <div className="modal-content">
                            <div className="popup_modal ">
                              <button
                                type="button"
                                className="close btn_popup ads"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                              <div className="main_heading">
                                <h2>Add Year</h2>
                              </div>
                              <div className="dashed_popup">
                                <form role="form " onSubmit={handleSubmit}>
                                  <div className="form_flex">
                                    <div className="form-group">
                                      <label for="uname">
                                        Year <text className="starm">*</text>
                                      </label>
                                      <input
                                        onKeyPress={handleKeyPress}
                                        autoComplete="off"
                                        className="name"
                                        type="text"
                                        max={currentYear}
                                        min={1950}
                                        placeholder="Enter Year"
                                        onChange={handleInputChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                  {!isValid && (
                                    <p style={{ color: "red" }}>
                                      Please enter a valid four-digit year.
                                    </p>
                                  )}
                                  <div className="btn_main">
                                    <button
                                      type="submit"
                                      className="btn_save"

                                    >
                                      Save
                                    </button>
                                    <button
                                      type="button"
                                      onClick={clearForm}
                                      className="btn_save btn_cancle"
                                      data-dismiss="modal"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/**************************** Delete ****************************************/}
                      <div
                        className="modal fade sc_modal"
                        id="exampleModal"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              />
                            </div>
                            <div className="modal-body">
                              <h4>Delete Row</h4>
                              <p>
                                Are you sure you want to delete this account?
                              </p>
                              <ul>
                                <li>
                                  <a
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Cancel
                                  </a>
                                </li>
                                <li>
                                  <a
                                    data-bs-dismiss="modal"
                                    className="btn btn-danger"
                                    onClick={() => handleDeleteYear()}
                                  >
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div >
    </>
  );
};
export default Year;
