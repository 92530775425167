import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const baseUrl = 'https://api.lawyerhunt.in/api/';
const LoginApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    authenticate: builder.mutation({
      query: (credentials) => ({
        url: 'SuperAdmin/Login',
        method: 'POST',
        headers: {
          Accept:'application/json',
          'Content-Type': 'multipart/form-data',
        },
        body: credentials,
      }),
    }),
  }),
});
export const { useAuthenticateMutation } = LoginApi;
export default LoginApi;
