import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const DeleteYear = createApi({
  reducerPath: "DeleteYear",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.lawyerhunt.in/api/Master/DeleteYear",

    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        headers.set("Authorization", `${token}`);
        headers.set("Accept", "*/*");
      }
    },
  }),

  endpoints: (builder) => ({
    getDeleteYear: builder.query({
      query: (Id) => {
        const formData = new FormData();
        formData.append("ID", Id);
        return {
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const { useGetDeleteYearQuery } = DeleteYear;

export default DeleteYear;
