import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import thunk from 'redux-thunk';
import LoginApi from '../Slice/LoginSlice';
import AddAdminapi from '../Slice/AddAdmin.js';
import ViewAdminApi from '../Slice/ViewAdmin';
import EditAdminapi from '../Slice/EditAdmin';
import DeleteAdminApi from '../Slice/DeleteAdmin';
import DeleteCaseType from '../Slice/DeleteCaseType'
import DeleteCourt from '../Slice/DeleteCourt'
import EditCourt from '../Slice/EditCourt';
import DeleteYear from '../Slice/DeleteYear';
export const Store = configureStore({
    reducer:{
        [LoginApi.reducerPath]:LoginApi.reducer,
        [AddAdminapi.reducerPath]:AddAdminapi.reducer,
        [ViewAdminApi.reducerPath]:ViewAdminApi.reducer,
        [EditAdminapi.reducerPath]:EditAdminapi.reducer,
        [DeleteAdminApi.reducerPath]:DeleteAdminApi.reducer,
        [DeleteCaseType.reducerPath]:DeleteCaseType.reducer,
        [DeleteCourt.reducerPath]:DeleteCourt.reducer,
        [EditCourt.reducerPath]:EditCourt.reducer,
        [DeleteYear.reducerPath]:DeleteYear.reducer,
    },
    middleware:(getDefaultMiddleware)=>
    getDefaultMiddleware()
    .concat(LoginApi.middleware,thunk)
    .concat(AddAdminapi.middleware,thunk)
    .concat(ViewAdminApi.middleware,thunk)
    .concat(EditAdminapi.middleware,thunk)
    .concat(DeleteAdminApi.middleware,thunk)
    .concat(DeleteCaseType.middleware)
    .concat(DeleteCourt.middleware)
   .concat(DeleteYear.middleware)
    .concat(EditCourt.middleware)
});
setupListeners(Store.dispatch)