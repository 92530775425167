import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import { FaUserPlus, FaUserMinus, FaUserTie } from "react-icons/fa";
const Dashboard = () => {
  const pieChartData = [
    { name: "A", value: 200 },
    { name: "B", value: 100 },
    { name: "C", value: 300 },
    { name: "D", value: 150 },
  ];
  const navigate = useNavigate();
  /*******************Cards data***************************************/
  const [countries, setCountries] = useState([]);
  const [ChartData, setChartData] = useState([]);
  const getCountries = async () => {
    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/SuperAdmin/ViewDashBoard",
        {
          method: "POST",

          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setChartData(data.result);
      setCountries(data.result);
      console.log(countries);
    } catch (error) {
      console.error(error);
    }
  };
  const [Name, setName] = useState([]);
  const [Values, setValues] = useState([]);
  {
    /*******************************Bar chart*****************************/
  }

  const getChartData = async () => {
    try {
      const response = await fetch(
        "https://api.lawyerhunt.in/api/SuperAdmin/BarChart",
        {
          method: "POST",

          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }

      setChartData(data.result);
      setName(data.result.Name);
      console.log(Name);
      setValues(data.result.Value);
      console.log(Values);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getCountries();
    getChartData();
  }, []);

  return (
    <>
      <div>
        <div className="wraper">
          <Header />
          <div className="mainWraper">
            <div className="menuOverlay"></div>
            <Sidebar />
            <div className="dashboradcontainer">
              <div className="accountContainer userListContainer">
                <div className="create_account_box userlistBox">
                  <div className="userListTable">
                    <div className="tableBox">
                      <div className="searDesign">
                        <div className="card_dash">



                         {/* ------------------Cards Added by KIran , Date=22-11-23------------------------- */}
                              {countries[0]  &&
                              countries[0].length > 0 ? (
                                countries[0].map((val, index) => (
                                  <div key={index}>
                                    <div className="lawyer_card row">
                                  
                                      <div className="col-md-4">
                                        <div className="crm_card">
                                          <div className="crm_card_flex">
                                            <figure>
                                              <img
                                                src="assets/img/icons/TotalAdmin.svg"
                                                alt=""
                                              />
                                            </figure>
                                            <div className="card_crmtext">
                                              <h5>{val.TotalAdmin}</h5>
                                              <a
                                                href="/#/view-company"
                                                title="View All Company"
                                              >
                                               TOTAL ADMIN
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="crm_card">
                                          <div className="crm_card_flex">
                                            <figure>
                                              <img
                                                src="assets/img/icons/ActiveAdmin.svg"
                                                alt=""
                                              />
                                            </figure>
                                            <div className="card_crmtext">
                                              <h5>{val.ActiveAdmin}</h5>
                                              <a
                                                href="/#/view-company"
                                                title="View All Company"
                                              >
                                               ACTIVE ADMIN
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="crm_card">
                                          <div className="crm_card_flex inv">
                                            <figure>
                                              <img
                                                src="assets/img/icons/DeActiveAdmin.svg"
                                                alt=""
                                              />
                                            </figure>
                                            <div className="card_crmtext">
                                              <h5>
                                                {val.DeactiveAdmin} 
                                              </h5>
                                              <a
                                                href="/#/view-company"
                                                title="View All Company"
                                              >
                                               DEACTIVE ADMIN

                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="crm_card">
                                          <div className="crm_card_flex Expense">
                                            <figure>
                                              <img
                                                src="assets/img/icons/client.svg"
                                                alt=""
                                              />
                                            </figure>
                                            <div className="card_crmtext">
                                              <h5>
                                                {val.TotalClients} 
                                              </h5>
                                              <a
                                               
                                              >
                                               TOTAL CLIENT
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="crm_card">
                                          <div className="crm_card_flex Amt">
                                            <figure>
                                              <img
                                                src="assets/img/icons/case.svg"
                                                alt=""
                                              />
                                            </figure>
                                            <div className="card_crmtext">
                                              <h5>
                                                {val.TotalCase} 
                                              </h5>
                                              <a
                                               
                                              >
                                                TOTAL CASE
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="crm_card">
                                          <div className="crm_card_flex appoint">
                                             <figure>
                                              <img
                                                src="assets/img/icons/case.svg"
                                                alt=""
                                              />
                                            </figure>
                                            <div className="card_crmtext">
                                              <h5>{val.TotalCaseLead}</h5>
                                              <a>
                                                
                                               TOTAL CASE LEAD

                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              
                            ) : (
                              <div>Loading...</div>
                            )} 
                          </div>
                        


                        {/***************************Upcoming Expired User List*********************************/}
                        {countries[1] &&
                            countries[1].length > 0 &&
                            countries[1].message === "Data not found" ? (
                              <h3 className="visuald">
                                There are no records to display
                              </h3>
                            ) : (
                              <>
                        <div className="upcome_wrap">
                          <h3 style={{ color: "blue" }}>
                            Upcoming Expired Company List
                            <a
                              href="/#/view-company"
                              className="btn btn-primary"
                              style={{ float: "right" }}
                            >
                              View All
                            </a>
                          </h3>

                          <Table
                            stripped
                            bordered
                            hover
                            variant="light"
                            size="md"
                          >
                           
                                <thead>
                                  <tr>
                                    <th width="70">S no.</th>
                                    <th width="370">Name</th>
                                    <th width="170">Mobile .No</th>
                                    <th width="370">Address</th>
                                    <th width="250">Activation Date</th>
                                    <th width="250">Activation From</th>
                                    <th width="250">Activation To</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {countries[1] && countries[1].length > 1 ? (
                                    countries[1].map((val, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{val.Name}</td>
                                        <td>{val.MobileNo}</td>
                                        <td>{val.Address}</td>
                                        <td>{val.ActivationDate}</td>
                                        <td>{val.ActivateFrom}</td>
                                        <td>{val.ActivateTo}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <div>Loading...</div>
                                  )}
                                </tbody>
                             
                          </Table>
                        </div>
                        </>
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
