import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router";
import Home from "./Components/Home";
import Login from "./Components/Login";
import ViewUser from "./Components/ViewUser";
import Error from "./Components/Error";
import Court from "./Components/Court";
import CaseType from "./Components/CaseType";
import Year from "./Components/Year";
import Activity from "./Components/Activity";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Citycourt from "./Components/Citycourt";
import Dashboard from "./Components/Dashboard";
import jwt_decode from "jwt-decode";
const App = () => {
  const navigate = useNavigate();
  
  const token = localStorage.getItem("access_token");

  useEffect(() => {
    // Function to check if the token has expired
    const isTokenExpired = () => {
      if (!token) {
        navigate("/");
        return true;
      }

      try {
        // Decode the token to extract the expiration timestamp
        const decodedToken = jwt_decode(token);
        const expirationTime = decodedToken.exp * 10000;
        // Get the current timestamp
        const currentTime = Date.now();
        if (currentTime >= expirationTime) {
          navigate("/");
          return true;
        }
        return null;
      } catch (error) {
        navigate("/");
        return true;
      }
    };
    isTokenExpired();
  }, [token, navigate]);

  return (
    <>
      <div>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/" element={<Login />}></Route>
          <Route path="/view-company" element={<ViewUser />}></Route>
          <Route path="*" element={<Error />}></Route>
          <Route path="/view-court" element={<Court />}></Route>
          <Route path="/view-city-court" element={<Citycourt />}></Route>
          <Route path="/view-case-type" element={<CaseType />}></Route>
          <Route path="/view-year" element={<Year />}></Route>

          <Route path="/view-activity" element={<Activity />}></Route>
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
};

export default App;
