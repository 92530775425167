import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const DeleteAdminApi = createApi({
  reducerPath: 'DeleteAdminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.lawyerhunt.in/api/SuperAdmin/DeleteAdmin',

    prepareHeaders: (headers) => {
      const token = localStorage.getItem('access_token'); 

      if (token) {
        headers.set('Authorization', `${token}`);
        headers.set(  'Accept', '*/*')
      }
      
    },
  }),

  endpoints: (builder) => ({
    getDeleteAdmin: builder.query({
      query: (Id) => {
       
        const formData = new FormData();
        formData.append("ID", Id );
    
       
    
        return {
        
          method: 'POST',
    
          body:formData
        };
      },
    }),
  }),
});

export const { useGetDeleteAdminQuery } = DeleteAdminApi;

export default DeleteAdminApi;