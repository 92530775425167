import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const ViewAdminApi = createApi({
  reducerPath: "ViewAdminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.lawyerhunt.in/api/SuperAdmin/ViewAdmin",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        headers.set("Authorization", `${token}`);
        headers.set("Accept", "*/*");
      }
    },
  }),
  endpoints: (builder) => ({
    getViewAdmin: builder.query({
      query: () => {
        return {
          method: "POST",
          body: " ",
        };
      },
    }),
  }),
});

export const { useGetViewAdminQuery } = ViewAdminApi;
export default ViewAdminApi;
